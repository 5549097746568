import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./index.scss";
import loadable from "@loadable/component";
import { isMobile } from "react-device-detect";
import { Router } from "@reach/router";
import CategoriesComponent from "../components/CategoriesComponents/CategoriesComponent";
import CategoryComponent from "../components/CategoriesComponents/CategoryComponent";

const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"));

const Index = () => {
  return (
    <Layout>
      {!isMobile && <AnimatedCursor />}
      <SEO title="Home" />
      <Router>
        <CategoriesComponent path="/" />
        <CategoryComponent path="/categories/:name" />
      </Router>
    </Layout>
  );
};

export default Index;
